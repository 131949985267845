.signup {
  #Input__container {
    @apply my-2;

    .Input {
      outline: none;
      width: 100%;
      @apply border border-neutral-300 rounded-2xl;

      &:focus,
      &:active {
        @apply border-primary opacity-100;
      }

      &:not(:focus):not(:active):valid {
        @apply border-neutral-300 opacity-100;
      }

      // Hide arrows/spinners from <input type="number"/>
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
      /* Firefox */
      &[type="number"] {
        appearance: textfield;
      }

      // Sizes
      &--sm {
        @apply px-1 py-1 text-sm xl:py-2 xl:px-2 xl:text-base;
      }

      &--md {
        @apply px-2 py-2 text-base xl:px-3 xl:py-3 xl:text-lg;
      }

      &--lg {
        @apply px-3 py-3 text-lg xl:py-4 xl:px-4 xl:text-xl;
      }

      // Colors
      &--neutral {
        @apply border-neutral-300 focus:ring-neutral-300 focus:border-neutral-300;
      }

      &--primary {
        @apply border-primary-300 focus:ring-primary-300 focus:border-primary-300;
      }

      &--success {
        @apply border-success-300 focus:ring-success-300 focus:border-success-300;
      }

      &--danger {
        @apply border-danger-300 focus:ring-danger-300 focus:border-danger-300;
      }

      &--warning {
        @apply border-warning-300 focus:ring-warning-300 focus:border-warning-300;
      }

      &--info {
        @apply border-info-300 focus:ring-info-300 focus:border-info-300;
      }

      &--error {
        @apply border-danger-500 focus:ring-danger-500 focus:border-danger-500;
      }
    }

    .Input__checkmark {
      @apply absolute -top-1 -right-1 block hidden;
    }

    .Input:focus:valid ~ .Input__checkmark-focused,
    .Input:active:valid ~ .Input__checkmark-focused {
      @apply block;
    }

    .Input:not(:focus):not(:active):valid ~ .Input__checkmark-valid {
      @apply block opacity-100;
    }
  }
}
