span.MuiSlider-root {
  .MuiSlider-markLabel {
    top: -24px;
    @apply text-neutral-600 text-base;
  }

  .MuiSlider-valueLabel {
    @apply bg-primary-700;
  }
}
