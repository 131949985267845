@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// stylelint-disable scss/at-import-partial-extension-disallowed-list
@import "fonts/**/*.scss";
// stylelint-enable scss/at-import-partial-extension-disallowed-list

h1 {
  @apply text-2xl;
}

h2 {
  @apply text-xl;
}

h3 {
  @apply text-lg;
}
