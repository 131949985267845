.SignUp {
  @apply h-full w-full;

  > form {
    @apply h-full w-full;

    &:invalid .button-group > button[type="submit"] {
      @apply bg-neutral-100;
      @apply text-neutral-500;
    }
  }

  .question-responsive {
    @apply h-full w-full flex flex-col;
    @apply lg:flex-row;

    .question-container {
      @apply p-8 flex flex-col justify-between overflow-y-auto;
      @apply lg:px-16 lg:w-2/3;

      .question-group {
        @apply grow;
        @apply lg:flex lg:flex-col lg:justify-center;
      }

      .button-group {
        @apply flex justify-end gap-4;
        @apply pt-4 lg:justify-start;
      }
    }
  }
}
