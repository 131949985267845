.TextareaInput {
  border-radius: 10px;
  height: 370px;
  opacity: 0.2;
  outline: none;
  padding: 27px 20px;
  resize: none;
  width: 100%;
  @apply border-2 border-secondary-700;

  &:focus,
  &:active {
    opacity: 1;
    @apply border-primary;
  }

  &:not(:empty) {
    opacity: 1;
  }
}
