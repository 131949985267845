.RadioButton {
  -webkit-tap-highlight-color: transparent;
  @apply flex items-center gap-3 border-[1px] border-neutral-300 rounded-3xl py-[18px] px-4 w-full cursor-pointer;

  &:has(&__input:checked) {
    @apply border-primary-600 bg-primary-400 text-primary-900;
  }

  &__input {
    @apply accent-primary-400 border-primary-400 border-[1px] h-6 w-6 cursor-pointer rounded-full relative appearance-none;

    &:checked::before {
      content: "";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @apply border-primary-600 border-[6px] absolute w-6 h-6 bg-white rounded-full;
    }
  }
}
