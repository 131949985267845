.radio-option {
  @apply my-4;
  @apply lg:my-2;
}

.slider-range-label {
  @apply flex flex-row justify-between;
}

.descriptive-question {
  font-family: "Plex Serif";
  font-size: 20px;
  font-weight: 500;
  @media (min-width: 1024px) {
    font-size: 22px;
  }
}
