.SurveyComponent {
  @apply h-full w-full;

  > form {
    &:invalid .button-group > button[type="submit"] {
      @apply bg-neutral-100;
      @apply text-neutral-500;
    }

    .step-indicator {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }
  }
}
