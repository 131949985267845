.timepicker {
  @apply text-center w-full;
  @apply lg:text-start;

  &.react-time-picker {
    &.react-time-picker--open > div {
      @apply border-primary opacity-100;
    }

    &:not(.react-time-picker--open):has(
        input.react-time-picker__inputGroup__hour:valid
      ):has(input.react-time-picker__inputGroup__minute:valid)
      > div {
      @apply border-neutral-300 opacity-100;
    }
  }
}

.timepicker__checkmark {
  @apply absolute -top-1 -right-1 hidden;
}

.timepicker:not(.react-time-picker--open).react-time-picker:has(
    input.react-time-picker__inputGroup__hour:valid
  ):has(input.react-time-picker__inputGroup__minute:valid)
  ~ .timepicker__checkmark-valid {
  @apply block;
}

.react-time-picker__inputGroup {
  @apply text-3xl flex justify-between gap-1.5 items-center;
}

.react-time-picker__inputGroup__input {
  &.react-time-picker__inputGroup__hour,
  &.react-time-picker__inputGroup__minute {
    @apply bg-white rounded-2xl grow p-4 border-[1px] border-neutral-300 text-center;
  }

  &.react-time-picker__inputGroup__hour::placeholder,
  &.react-time-picker__inputGroup__minute::placeholder {
    @apply text-neutral-700 text-center;
  }
}

.react-time-picker__inputGroup__leadingZero {
  display: none;
}
